import React from "react";
import { Link } from "react-router-dom";
import { ModelManager } from "@mjcom/model-manager";
import { TaskForm } from "./TaskForm";

export default class UpdateTask extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
      error: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    this.getElement();
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/tasks", this.props.match.params.taskId)
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  render() {
    return (
      <>
        {this.state.isLoading === false && this.state.element && (
          <ul className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/projects">Projets</Link>
            </li>
            <li>
              <Link to={`/project/${this.state.element.project.id}`}>
                {this.state.element.project.name}
              </Link>
            </li>
            <li className="active">Tâche</li>
          </ul>
        )}

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Modifier une tâche
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <p>
                Indiquer les informations suivantes pour modifier votre tâche
              </p>
            </div>
            <div className="panel-body form-group-separated">
              {this.state.isLoading && (
                <div className="loading">
                  <img src="/loading.gif" alt="loading" />
                </div>
              )}
              {this.state.isLoading === false && this.state.element && (
                <TaskForm task={this.state.element} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
