import { createBrowserHistory } from "history";
import { HistoryManager } from "@mjcom/history-manager";

import { operationService } from "./Service/OperationService";

const baseName = `/app/${operationService.getSlug()}`;

HistoryManager.setHistory(createBrowserHistory({ basename: baseName }));

export const history = HistoryManager.getHistory();
