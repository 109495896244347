import React from "react";
import { Link } from "react-router-dom";
import { ProjectTypeForm } from "./ProjectTypeForm";

export default class CreateProjectType extends React.Component {
  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Admin</Link>
          </li>
          <li>
            <Link to="/admin/task-types">Types de tâches</Link>
          </li>
          <li className="active">Créer un type de projets</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Créer un type de
            projets
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <p>
                Indiquer les informations suivantes pour créer votre type de
                projets
              </p>
            </div>
            <div className="panel-body form-group-separated">
              <ProjectTypeForm />
            </div>
          </div>
        </div>
      </>
    );
  }
}
