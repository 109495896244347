import React from "react";
import ReactDOM from "react-dom";
import { SessionManager } from "session-manager";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { store } from "./Helper";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { operationService } from "./Service/OperationService";

const env = process.env.REACT_APP_ENV || "prod";

if (env === "prod") {
  LogRocket.init("sks4it/workload");
  setupLogRocketReact(LogRocket);
}

SessionManager.setTokenKey(`app-${operationService.getSlug()}`);
SessionManager.setTokenKeyExpired(`expired-${operationService.getSlug()}`);
SessionManager.setAppSlug(operationService.getSlug());

ReactDOM.render(
  <IntlProvider locale="fr">
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
