import React from "react";
import { Link } from "react-router-dom";
import { TaskForm } from "./TaskForm";

export default function CreateTask() {
  return (
    <>
      <ul className="breadcrumb">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="active">Créer une tâche</li>
      </ul>

      <div className="page-title">
        <h2>
          <span className="fa fa-arrow-circle-o-left" /> Créer une tâche
        </h2>
      </div>

      <div className="page-content-wrap">
        <div className="panel panel-default">
          <div className="panel-body">
            <p>Indiquer les informations suivantes pour créer votre tâche.</p>
          </div>
          <div className="panel-body form-group-separated">
            <TaskForm />
          </div>
        </div>
      </div>
    </>
  );
}
