import React from "react";
import { Link } from "react-router-dom";
import { HolidayForm } from "./HolidayForm";

export default function createHoliday() {
  return (
    <>
      <ul className="breadcrumb">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/holidays">Absences</Link>
        </li>
        <li className="active">Créer une absence</li>
      </ul>

      <div className="page-title">
        <h2>
          <span className="fa fa-arrow-circle-o-left" /> Créer une absence
        </h2>
      </div>

      <div className="page-content-wrap">
        <div className="panel panel-default">
          <div className="panel-body">
            <p>Indiquer les informations suivantes pour créer votre absence</p>
          </div>
          <div className="panel-body form-group-separated">
            <HolidayForm />
          </div>
        </div>
      </div>
    </>
  );
}
