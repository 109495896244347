import { Confirm as BaseConfirm, LoginForm } from "@mjcom/user-login-component";
import React from "react";

export class Confirm extends BaseConfirm {
  render() {
    return (
      <div className="login-container">
        <div className="login-box animated fadeInDown">
          <div className="login-logo" />
          <p>
            {this.state.success !== null ? (
              this.state.success ? (
                <div className="alert alert-success" role="alert">
                  Votre compte est validé, vous pouvez vous connectez avec les
                  identifiants saisis lors de votre inscription.
                </div>
              ) : (
                <div className="alert alert-danger" role="alert">
                  Une erreur est survenue. Veuillez vérifier votre lien de
                  confirmation.
                </div>
              )
            ) : (
              <div className="loading">
                <img src="/loading.gif" alt="loading" />
              </div>
            )}
          </p>
          <div className="login-body">
            <div className="login-title">
              <strong>Welcome</strong>, Please login
            </div>
            <LoginForm />
          </div>
          <div className="login-footer">
            <div className="pull-left">&copy; 2019 Workload</div>
            <div className="pull-right" />
          </div>
        </div>
      </div>
    );
  }
}
