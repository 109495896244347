import React from "react";
import { ResetPasswordForm } from "@mjcom/user-login-component";

class ResetPassword extends React.Component {
  render() {
    return (
      <div className="registration-container">
        <div className="registration-box animated fadeInDown">
          <div className="registration-logo" />
          <div className="registration-body">
            <div className="registration-title">
              <strong>Forgot</strong> Password?
            </div>
            <div className="registration-subtitle">
              Veuillez saisir votre nouveau mot de passe.
            </div>
            <ResetPasswordForm token={this.props.token} />
          </div>
          <div className="registration-footer">
            <div className="pull-left">&copy; 2019 Workload</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
