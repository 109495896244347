import React from "react";
import { ElementForm, Input } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import { connect } from "react-redux";
import { history } from "../../History";

class ProfileForm extends ElementForm {
  constructor(props) {
    const user = {
      id: props.user.id,
      email: props.user.email,
      firstname: props.user.firstname,
      lastname: props.user.lastname,
      phone: props.user.phone,
      address: props.user.address,
      zipcode: props.user.zipcode,
      city: props.user.city,
    };
    user["@id"] = `/api/users/${user.id}`;
    user.repeatEmail = user.email;
    user.plainPassword = "";
    user.repeatPassword = "";

    const requiredFields = ["email", "repeatEmail", "firstname", "lastname"];

    super(props, user, requiredFields);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    window.scrollTo(0, 0);

    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (this.state.element.email !== this.state.element.repeatEmail) {
      violations.push({
        propertyPath: "email",
        message: "Les champs emails ne correspondent pas.",
      });
    }

    if (
      this.state.element.plainPassword !== this.state.element.repeatPassword
    ) {
      violations.push({
        propertyPath: "plainPassword",
        message: "Les champs mot de passe ne correspondent pas.",
      });
    }

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      ModelManager.save(this.state.element)
        .finally(() => this.setState({ isLoading: false }))
        .then((element) => {
          const user = { ...element };
          user.repeatEmail = element.email;
          user.plainPassword = "";
          user.repeatPassword = "";
          this.setState({ element: user });
          history.push("/user/profile");
          dispatch(alertActions.success("Votre profil a été mis à jour."));
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier vos informations."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier vos informations."
        )
      );
    }
  }

  render() {
    return (
      <div className="container profile page">
        <div className="title">Mon profil</div>

        {this.state.isLoading === false ? (
          <form className="ajax-contact-form" noValidate>
            <h5 className="clear">Mes identifiants</h5>
            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  value={this.state.element.email}
                  errors={this.state.errors.email}
                  updateElement={this.updateElement}
                  required={this.isRequired("email")}
                  disabled
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="repeatEmail"
                  label="Confirmez votre email"
                  type="email"
                  value={this.state.element.repeatEmail}
                  errors={this.state.errors.repeatEmail}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatEmail")}
                  disabled
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="plainPassword"
                  label="Mot de passe"
                  type="password"
                  value={this.state.element.plainPassword}
                  errors={this.state.errors.plainPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("plainPassword")}
                  disabled={false}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="repeatPassword"
                  label="Confirmez votre mot de passe"
                  type="password"
                  value={this.state.element.repeatPassword}
                  errors={this.state.errors.repeatPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatPassword")}
                  disabled={false}
                />
              </div>
            </div>

            <h5 className="clear">Mes coordonnées</h5>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="firstname"
                  label="Prénom"
                  type="text"
                  value={this.state.element.firstname}
                  errors={this.state.errors.firstname}
                  updateElement={this.updateElement}
                  required={this.isRequired("firstname")}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="lastname"
                  label="Nom"
                  type="text"
                  errors={this.state.errors.lastname}
                  value={this.state.element.lastname}
                  updateElement={this.updateElement}
                  required={this.isRequired("lastname")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="phone"
                  label="Téléphone"
                  type="text"
                  errors={this.state.errors.phone}
                  value={this.state.element.phone}
                  updateElement={this.updateElement}
                  required={this.isRequired("phone")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="address"
                  label="Adresse"
                  type="text"
                  errors={this.state.errors.address}
                  value={this.state.element.address}
                  updateElement={this.updateElement}
                  required={this.isRequired("address")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="zipcode"
                  label="Code postal"
                  type="text"
                  errors={this.state.errors.zipcode}
                  value={this.state.element.zipcode}
                  updateElement={this.updateElement}
                  required={this.isRequired("zipcode")}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="city"
                  label="Ville"
                  type="text"
                  errors={this.state.errors.city}
                  value={this.state.element.city}
                  updateElement={this.updateElement}
                  required={this.isRequired("city")}
                />
              </div>
            </div>

            <div className="col-md-10">
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Modifier
              </button>
            </div>

            <div className="col-md-10">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const ConnectedProfileForm = connect(mapStateToProps)(ProfileForm);
export { ConnectedProfileForm as ProfileForm };
