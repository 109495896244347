import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Modal } from "react-bootstrap";
import "react-dates/lib/css/_datepicker.css";
import "./App.css";
import {
  LoginForm,
  Logout,
  PrivateRoute,
  PublicRoute,
  RegisterForm,
} from "@mjcom/user-login-component";
import { alertActions, userActions } from "@mjcom/user-manager";
import { SessionManager } from "session-manager";
import { ConnectedLayout } from "./Component/Layout/ConnectedLayout";
import { AdminLayout } from "./Component/Layout/AdminLayout";
import { Profile } from "./Component/Profile/Profile";
import { Tasks } from "./Component/Task/Tasks";
import NotFound from "./Component/NotFound/NotFound";
import Login from "./Component/Login/Login";
import Home from "./Component/Home/Home";
import Holidays from "./Component/Holiday/Holidays";
import Project from "./Component/Project/Project";
import User from "./Component/User/User";
import Projects from "./Component/Project/Projects";
import Task from "./Component/Task/Task";
import { Planning } from "./Component/Planning/Planning";
import CreateProject from "./Component/Project/CreateProject";
import CreateTask from "./Component/Task/CreateTask";
import Password from "./Component/Login/Password";
import CreateProjectType from "./Component/ProjectType/CreateProjectType";
import ProjectTypes from "./Component/ProjectType/ProjectTypes";
import CreateTaskType from "./Component/TaskType/CreateTaskType";
import { TaskTypes } from "./Component/TaskType/TaskTypes";
import CreateTeam from "./Component/Team/CreateTeam";
import { Members } from "./Component/Team/Members";
import Teams from "./Component/Team/Teams";
import { operationService } from "./Service/OperationService";
import ResetPassword from "./Component/Login/ResetPassword";
import UserCreate from "./Component/User/UserCreate";
import CreateHoliday from "./Component/Holiday/CreateHoliday";
import UpdateHoliday from "./Component/Holiday/UpdateHoliday";
import UpdateTask from "./Component/Task/UpdateTask";
import UpdateProject from "./Component/Project/UpdateProject";
import UserUpdate from "./Component/User/UserUpdate";
import AdminHome from "./Component/Admin/AdminHome";
import { history } from "./History";
import { OccupancyRate } from "./Component/OccupancyRate/OccupancyRate";
import { Confirm } from "./Component/Login/Confirm";
import Version from "./Component/Configuration/Version";

class App extends Component {
  constructor(props) {
    super(props);

    this.interval = null;

    window.location.hash = "";

    this.state = {
      operation: null,
      isLoading: false,
      isAlertShow: false,
    };

    const { dispatch } = this.props;
    this.hideAlert = this.hideAlert.bind(this);
    this.logout = this.logout.bind(this);

    history.listen(() => {
      // clear alert on location change
      dispatch(alertActions.clear());
      dispatch(userActions.getConnectedUser());
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getOperation();
    this.props.dispatch(userActions.getConnectedUser());
    this.interval = setInterval(
      () => this.props.dispatch(userActions.getConnectedUser()),
      60000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getOperation() {
    operationService
      .getOperation()
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((operation) => {
        this.setState({ operation });
      })
      .catch(() => {
        this.setState({ isError: true });
      });
  }

  hideAlert() {
    this.props.dispatch(alertActions.clear());
  }

  logout() {
    this.props.dispatch(userActions.logout());
  }

  render() {
    const { alert, user } = this.props;
    const { isLoading, isError, operation } = this.state;

    const resetPasswordForm = ({ match }) => (
      <ResetPassword token={match.params.token} />
    );
    const regexUUID =
      "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

    return (
      <>
        <Version />
        {isError === true && (
          <div className="container text-center">
            <div className="col-md-6 alert alert-danger">
              Oups, une erreur est survenue ...
            </div>
          </div>
        )}
        {isLoading === true && (
          <div className="loading">Chargement de l&apos;operation...</div>
        )}
        {isLoading === false && !operation && (
          <div className="loading">
            <NotFound
              message={[
                "L'opération n'existe pas.",
                <br />,
                "Vous devez vérifier que vous avez utilisé l'url dédiée à votre espace (voir l'email de confirmation d'inscription) au format https://app.workload.fr/app/",
                <b className="slug">votre-espace-dedie</b>,
                ".",
              ]}
            />
          </div>
        )}
        {isLoading === false && operation && (
          <>
            {alert.message && (
              <div className="error-occurred">
                <Alert
                  bsStyle={alert.type.replace("alert-", "")}
                  onDismiss={this.hideAlert}
                >
                  {alert.message}
                </Alert>
              </div>
            )}

            <Modal
              show={SessionManager.isExpired()}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
              centered
            >
              <Modal.Header>
                <Modal.Title>S'identifier</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Pour des raisons de sécurité nous allons procéder à la
                  vérification de votre identité.
                </p>
                <LoginForm />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  id="logout"
                  className="btn btn-secondary"
                  onClick={this.logout}
                  data-dismiss="modal"
                >
                  Se déconnecter
                </button>
              </Modal.Footer>
            </Modal>

            <Router history={history}>
              <>
                <Switch>
                  <PublicRoute exact path="/login" component={Login} />
                  <PublicRoute
                    exact
                    path="/confirm/:confirmationToken([a-f0-9]{32})"
                    render={(props) => (
                      <Confirm
                        confirmationToken={props.match.params.confirmationToken}
                        {...props}
                      />
                    )}
                  />

                  <PublicRoute path="/register" component={RegisterForm} />
                  <Route
                    exact
                    path="/password/get-new-password"
                    component={Password}
                  />
                  <Route
                    exact
                    path={`/password/reset/:token`}
                    component={resetPasswordForm}
                  />

                  <ConnectedLayout
                    exact
                    path="/"
                    render={(props) => <Home user={user} {...props} />}
                  />
                  <ConnectedLayout
                    exact
                    path="/team/planning"
                    component={Planning}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <Task user={user} {...props} />}
                    path={`/task/:taskId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <User user={user} {...props} />}
                    path={`/user/:userId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <UserUpdate user={user} {...props} />}
                    path={`/user/update/:userId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    path="/user/create"
                    render={(props) => <UserCreate user={user} {...props} />}
                  />
                  <ConnectedLayout
                    exact
                    path="/user/profile"
                    render={(props) => <Profile user={user} {...props} />}
                  />
                  <ConnectedLayout
                    exact
                    path="/occupancy/rate"
                    component={OccupancyRate}
                  />
                  <ConnectedLayout
                    exact
                    path="/task/create"
                    component={CreateTask}
                  />
                  <ConnectedLayout
                    exact
                    path="/tasks"
                    render={(props) => <Tasks user={user} {...props} />}
                  />
                  <AdminLayout
                    exact
                    path="/admin"
                    render={(props) => <AdminHome user={user} {...props} />}
                  />
                  <AdminLayout
                    exact
                    path="/admin/task-types"
                    render={(props) => <TaskTypes user={user} {...props} />}
                  />
                  <AdminLayout
                    exact
                    path="/admin/task-type/create"
                    component={CreateTaskType}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <UpdateTask user={user} {...props} />}
                    path={`/task/update/:taskId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <Project user={user} {...props} />}
                    path={`/project/:projectId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    path="/projects"
                    render={(props) => <Projects user={user} {...props} />}
                  />
                  <ConnectedLayout
                    exact
                    path="/project/create"
                    component={CreateProject}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <UpdateProject user={user} {...props} />}
                    path={`/project/update/:projectId(${regexUUID})`}
                  />
                  <AdminLayout
                    exact
                    path="/admin/project-types"
                    render={(props) => <ProjectTypes user={user} {...props} />}
                  />
                  <AdminLayout
                    exact
                    path="/admin/project-type/create"
                    component={CreateProjectType}
                  />
                  <ConnectedLayout
                    exact
                    path="/holidays"
                    render={(props) => <Holidays user={user} {...props} />}
                  />
                  <ConnectedLayout
                    exact
                    path="/holiday/create"
                    component={CreateHoliday}
                  />
                  <ConnectedLayout
                    exact
                    render={(props) => <UpdateHoliday user={user} {...props} />}
                    path={`/holiday/update/:holidayId(${regexUUID})`}
                  />
                  <ConnectedLayout
                    exact
                    path="/members"
                    render={(props) => <Members user={user} {...props} />}
                  />
                  <AdminLayout
                    exact
                    path="/admin/team/create"
                    component={CreateTeam}
                  />
                  <AdminLayout
                    exact
                    path="/admin/teams"
                    render={(props) => <Teams user={user} {...props} />}
                  />
                  <PrivateRoute exact path="/logout" component={Logout} />

                  <Route component={NotFound} />
                </Switch>
              </>
            </Router>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, user } = state;

  return {
    alert,
    authentication,
    user,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
