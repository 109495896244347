import React, { useState, useEffect } from "react";
import { API_URL, getHeaders } from "@mjcom/model-manager";
import { Link } from "react-router-dom";

function Check(props) {
  const [checks, setChecks] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = {
      headers: getHeaders(),
      method: "GET",
    };

    setLoading(true);
    fetch(`${API_URL}/api/configuration/check`, init)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        setChecks(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="configuration-check">
      {!loading && props.user.admin && (
        <div>
          {false === checks["team"] && (
            <p className="alert alert-danger" role="alert">
              Vous devez <Link to="/admin/teams">ajouter une équipe</Link> pour
              finaliser votre environnement.
            </p>
          )}
          {false === checks["project_type"] && (
            <p className="alert alert-danger" role="alert">
              Vous devez{" "}
              <Link to="/admin/project-types">ajouter un type de projet</Link>{" "}
              pour finaliser votre environnement.
            </p>
          )}
          {false === checks["task_type"] && (
            <p className="alert alert-danger" role="alert">
              Vous devez{" "}
              <Link to="/admin/task-types">ajouter un type de tâche</Link> pour
              finaliser votre environnement.
            </p>
          )}
          {false === checks["workload_user"] && (
            <p className="alert alert-danger" role="alert">
              Vous devez <Link to="/members">ajouter des utilisateurs</Link>{" "}
              pour finaliser votre environnement (chef de projet, développeur,
              ...).
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default Check;
