import React from "react";
import { Link } from "react-router-dom";
import "whatwg-fetch";
import { SessionManager } from "session-manager";
import { API_URL, getPromise } from "@mjcom/model-manager";
import { ElementForm } from "@mjcom/form-component";
import { alertActions } from "@mjcom/user-manager";
import { connect } from "react-redux";
import { ProfileForm } from "./ProfileForm";
import { Upload } from "../Form/Upload";
import { history } from "../../History";

class Profile extends ElementForm {
  constructor(props) {
    const element = {
      "@id": "/api/media_objects",
      file: null,
    };

    const requiredFields = ["file"];

    super(props, element, requiredFields);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    window.scrollTo(0, 0);

    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (violations.length === 0) {
      this.setState({ isLoading: true });

      const HEADERS = new Headers();
      HEADERS.append("Accept", "application/json");
      HEADERS.append("Authorization", `Bearer ${SessionManager.getToken()}`);

      const formData = new FormData();
      formData.append("file", this.state.element.file);

      const init = {
        headers: HEADERS,
        method: "POST",
        body: formData,
      };

      getPromise(`${API_URL}/api/media_objects`, init)
        .finally(() => this.setState({ isLoading: false }))
        .then((res) => {
          history.push("/user/profile");
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier vos informations."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier vos informations."
        )
      );
    }
  }

  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Mon compte</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Mon compte
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <h3>
                <span className="fa fa-pencil" /> Ma photo de profil
              </h3>
              <p>Ajouter une photo à votre profil</p>
            </div>
            <div className="panel-body form-group-separated">
              <form className="ajax-contact-form" noValidate>
                <div className="form-row col-md-12">
                  <div className="col-md-5">
                    <Upload
                      name="file"
                      errors={this.state.errors.file}
                      updateElement={this.updateElement}
                      label="Votre photo"
                      required={this.isRequired("file")}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <button
                    type="submit"
                    className="btn btn-primary btn-rounded pull-right"
                    onClick={this.submit}
                  >
                    Modifier
                  </button>
                </div>

                <div className="col-md-10">
                  <small className="form-text text-muted">
                    (*) Champs requis.
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <h3>
                <span className="fa fa-pencil" /> Mes informations
              </h3>
              <p>Mettez à jour vos informations personnelles</p>
            </div>
            <div className="panel-body form-group-separated">
              {this.props.user && this.props.user.id && (
                <ProfileForm user={this.props.user} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps() {
  return {};
}

const ConnectedProfile = connect(mapStateToProps)(Profile);
export { ConnectedProfile as Profile };
