import React from "react";
import { GetNewPasswordForm } from "@mjcom/user-login-component";

class Password extends React.Component {
  render() {
    return (
      <div className="registration-container">
        <div className="registration-box animated fadeInDown">
          <div className="registration-logo" />
          <div className="registration-body">
            <div className="registration-title">
              <strong>Forgot</strong> Password?
            </div>
            <div className="registration-subtitle">
              Entrez votre email afin de recevoir un lien permettant de
              réinitialiser votre mot de passe.
            </div>
            <GetNewPasswordForm />
          </div>
          <div className="registration-footer">
            <div className="pull-left">&copy; 2019 Workload</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Password;
