import React from "react";
import { Date, Input, SelectAsync } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import moment from "moment";
import "moment/locale/fr";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "abortcontroller-polyfill";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { history } from "../../History";

class ProjectForm extends AsyncElementForm {
  constructor(props) {
    let element = {
      "@id": "/api/projects",
      name: "",
      beginAt: moment(),
      endAt: moment(),
      projectType: null,
      user: null,
      team: null,
    };

    if (props.project) {
      element = { ...props.project };
      element.user = {
        value: element.user["@id"],
        label: `${element.user.firstname}  ${element.user.lastname}`,
      };
      element.projectType = {
        value: element.projectType["@id"],
        label: element.projectType["name"],
      };
      element.team = {
        value: element.team["@id"],
        label: element.team["name"],
      };
      element.beginAt = moment(element.beginAt);
      element.endAt = moment(element.endAt);
    }

    const requiredFields = ["name", "beginAt", "endAt", "user", "team"];

    super(props, element, requiredFields);

    this.handleProjectTypeChange = this.handleProjectTypeChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadProjectTypes = this.loadProjectTypes.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      const element = { ...this.state.element };
      element.project = element.projectId;
      element.projectType = element.projectType.value;
      element.team = element.team.value;
      element.user = element.user.value;

      ModelManager.save(element)
        .finally(() => this.setState({ isLoading: false }))
        .then((project) => {
          history.push(`/project/${project.id}`);
          dispatch(
            alertActions.success("Votre action a été réalisée avec succés")
          );
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier votre saisie."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier votre saisie."
        )
      );
    }
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
  }

  handleProjectTypeChange(newValue) {
    this.handleChange("projectType", newValue);
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      {
        "order[fullname]": "asc",
        roles: "ROLE_PROJECT_MANAGER",
        enabled: true,
      },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  loadProjectTypes(inputValue) {
    return this.loadModels(inputValue, "name", "project_types", {
      "order[beginAt]": "asc",
      isEnabled: true,
    });
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams");
  }

  render() {
    return (
      <div>
        {this.state.isLoading === false ? (
          <form className="project-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Input
                    name="name"
                    label="Nom"
                    type="text"
                    value={this.state.element.name}
                    errors={this.state.errors.name}
                    updateElement={this.updateElement}
                    required={this.isRequired("name")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="beginAt"
                    label="Date de début"
                    type="text"
                    value={this.state.element.beginAt}
                    errors={this.state.errors.beginAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("beginAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="endAt"
                    label="Date de fin"
                    type="text"
                    value={this.state.element.endAt}
                    errors={this.state.errors.endAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("endAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="projectType"
                    label="Type de projet"
                    defaultOptions={true}
                    cache={false}
                    value={[this.state.element.projectType]}
                    errors={this.state.errors.projectType}
                    loadOptions={this.loadProjectTypes}
                    onChange={this.handleProjectTypeChange}
                    placeholder="Type de projet"
                    creatable={false}
                    required={this.isRequired("projectType")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="user"
                    defaultOptions={true}
                    label="Chef de projet"
                    value={this.state.element.user}
                    errors={this.state.errors.user}
                    loadOptions={this.loadUsers}
                    onChange={this.handleUserChange}
                    placeholder="Chef de projet"
                    creatable={false}
                    required={this.isRequired("user")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    defaultOptions={true}
                    name="team"
                    label="Equipe"
                    value={this.state.element.team}
                    errors={this.state.errors.team}
                    loadOptions={this.loadTeams}
                    onChange={this.handleTeamChange}
                    placeholder="Equipe"
                    creatable={false}
                    required={this.isRequired("team")}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <Link
                className="btn btn-danger btn-rounded pull-left"
                to="/projects"
              >
                Annuler
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Soumettre
              </button>
            </div>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedProjectForm = connect(mapStateToProps)(ProjectForm);
export { connectedProjectForm as ProjectForm };
