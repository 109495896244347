import React from "react";
import { Input, SelectAsync } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import { connect } from "react-redux";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { history } from "../../History";
import Checkbox from "../Form/Checkbox";

export const ROLES = [
  { label: "Administrateur", value: "ROLE_ADMIN" },
  { label: "Manager", value: "ROLE_TEAM_MANAGER" },
  { label: "Chef de projet", value: "ROLE_PROJECT_MANAGER" },
  { label: "Utilisateur", value: "ROLE_USER" },
];

class UserForm extends AsyncElementForm {
  constructor(props) {
    let element = {
      "@id": "/api/users",
      email: "",
      repeatEmail: "",
      plainPassword: "",
      repeatPassword: "",
      address: "",
      zipcode: "",
      city: "",
      firstname: "",
      lastname: "",
      phone: "",
      team: null,
      planned: true,
      roles: [],
    };

    const requiredFields = [
      "email",
      "repeatEmail",
      "firstname",
      "lastname",
      "team",
      "roles",
    ];

    if (props.user) {
      element = { ...props.user };
      element.roles = props.user.roles.map((element) => {
        return {
          value: element,
          label: ROLES.find((role) => role.value === element).label,
        };
      });
      element.team = element.team
        ? { value: element.team["@id"], label: element.team["name"] }
        : null;
      element.repeatEmail = element.email;
      element.plainPassword = "";
      element.repeatPassword = "";
    } else {
      requiredFields.push("plainPassword");
      requiredFields.push("repeatPassword");
    }

    super(props, element, requiredFields);

    if (!props.connectedUser.admin) {
      ROLES[0].disabled = true;
    }

    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleRolesChange = this.handleRolesChange.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams");
  }

  loadRoles() {
    return Promise.resolve(ROLES);
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
  }

  handleRolesChange(newValue) {
    this.handleChange("roles", newValue);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (this.state.element.roles.length === 0) {
      violations.push({
        propertyPath: "roles",
        message: "Ce champ est obligatoire.",
      });
    }

    if (this.state.element.email !== this.state.element.repeatEmail) {
      violations.push({
        propertyPath: "email",
        message: "Les emails ne correspondent pas.",
      });
    }

    if (
      this.state.element.plainPassword !== this.state.element.repeatPassword
    ) {
      violations.push({
        propertyPath: "plainPassword",
        message: "Les mots de passe ne correspondent pas.",
      });
    }

    if (violations.length === 0) {
      const element = { ...this.state.element };
      element.team = element.team.value;
      element.roles = element.roles.map((element) => element.value);

      ModelManager.save(element)
        .then((element) => {
          this.setState({ element });
          history.push("/members");

          dispatch(
            alertActions.success("L'utilisateur a été créé avec succès.")
          );
        })
        .catch((error) => {
          dispatch(
            alertActions.error(
              "Une erreur est survenue, merci de vérifier votre saisie."
            )
          );

          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
        });
    } else {
      this.mapErrors(violations);
    }
  }

  render() {
    return (
      <div>
        <div className="profile">
          <form className="ajax-contact-form form-horizontal" noValidate>
            <h5 className="clear">Identifiants</h5>
            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  value={this.state.element.email}
                  errors={this.state.errors.email}
                  updateElement={this.updateElement}
                  required={this.isRequired("email")}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="repeatEmail"
                  label="Confirmez l'email"
                  type="email"
                  value={this.state.element.repeatEmail}
                  errors={this.state.errors.repeatEmail}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatEmail")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="plainPassword"
                  label="Mot de passe"
                  type="password"
                  value={this.state.element.plainPassword}
                  errors={this.state.errors.plainPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("plainPassword")}
                  disabled={false}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="repeatPassword"
                  label="Confirmez le mot de passe"
                  type="password"
                  value={this.state.element.repeatPassword}
                  errors={this.state.errors.repeatPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatPassword")}
                  disabled={false}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <SelectAsync
                  defaultOptions={true}
                  name="team"
                  label="Equipe"
                  value={this.state.element.team}
                  errors={this.state.errors.team}
                  loadOptions={this.loadTeams}
                  onChange={this.handleTeamChange}
                  placeholder="Equipe"
                  creatable={false}
                  required={this.isRequired("team")}
                />
              </div>

              <div className="col-md-5">
                <SelectAsync
                  defaultOptions={true}
                  name="roles"
                  label="Rôles"
                  isMulti={true}
                  value={this.state.element.roles}
                  errors={this.state.errors.roles}
                  loadOptions={this.loadRoles}
                  onChange={this.handleRolesChange}
                  placeholder="Rôles"
                  creatable={false}
                  required={this.isRequired("roles")}
                />
              </div>
            </div>

            <h5 className="clear">Mes coordonnées</h5>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="firstname"
                  label="Prénom"
                  type="text"
                  value={this.state.element.firstname}
                  errors={this.state.errors.firstname}
                  updateElement={this.updateElement}
                  required={this.isRequired("firstname")}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="lastname"
                  label="Nom"
                  type="text"
                  errors={this.state.errors.lastname}
                  value={this.state.element.lastname}
                  updateElement={this.updateElement}
                  required={this.isRequired("lastname")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="phone"
                  label="Téléphone"
                  type="text"
                  errors={this.state.errors.phone}
                  value={this.state.element.phone}
                  updateElement={this.updateElement}
                  required={this.isRequired("phone")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="address"
                  label="Adresse"
                  type="text"
                  errors={this.state.errors.address}
                  value={this.state.element.address}
                  updateElement={this.updateElement}
                  required={this.isRequired("address")}
                />
              </div>
            </div>

            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Input
                  name="zipcode"
                  label="Code postal"
                  type="text"
                  errors={this.state.errors.zipcode}
                  value={this.state.element.zipcode}
                  updateElement={this.updateElement}
                  required={this.isRequired("zipcode")}
                />
              </div>

              <div className="col-md-5">
                <Input
                  name="city"
                  label="Ville"
                  type="text"
                  errors={this.state.errors.city}
                  value={this.state.element.city}
                  updateElement={this.updateElement}
                  required={this.isRequired("city")}
                />
              </div>
            </div>

            <h5 className="clear">Planning</h5>
            <div className="form-row col-md-12">
              <div className="col-md-5">
                <Checkbox
                  name="planned"
                  label="Afficher sur le planning"
                  errors={this.state.errors.planned}
                  value={this.state.element.planned}
                  className="form-check-input"
                  defaultChecked={this.state.element.planned}
                  updateElement={this.updateElement}
                  required={this.isRequired("planned")}
                />
              </div>
            </div>

            <div className="col-md-10">
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Soumettre
              </button>
            </div>
          </form>

          <div className="col-md-12">
            <small className="form-text text-muted">(*) Champs requis.</small>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const ConnectedUserForm = connect(mapStateToProps)(UserForm);
export { ConnectedUserForm as UserForm };
