import React from "react";
import { Link } from "react-router-dom";
import { UserForm } from "./UserForm";

export default class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/members">Membres</Link>
          </li>
          <li className="active">Créer un utilisateur</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" />
            &nbsp;Créer un utilisateur
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body form-group-separated">
              <UserForm connectedUser={this.props.user} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
