import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import { Link } from "react-router-dom";
import Moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import { history } from "../../History";
import MyProjects from "./MyProjects";
import MyHolidays from "./MyHolidays";
import MyTasks from "./MyTasks";

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    this.getElement();
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/users", this.props.match.params.userId)
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {});
  }

  delete(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir supprimer l'utilisateur "${element.firstname} ${element.lastname}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            ModelManager.delete("/api/users", this.props.match.params.userId)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                history.push("/members");
              })
              .catch(() => {});
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    Moment.locale("fr");

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/members">Membres</Link>
          </li>
          <li className="active">Utilisateur</li>
        </ul>

        {this.state.isLoading && (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
        {!this.state.isLoading && !this.state.element && (
          <div>Utilisateur introuvable</div>
        )}

        {!this.state.isLoading && this.state.element && (
          <div className="col-md-12">
            <div className="page-content-wrap posts">
              <div className="post-item">
                <div className="post-title">
                  {this.state.element.firstname} {this.state.element.lastname}{" "}
                  {this.state.element.team && (
                    <span>({this.state.element.team.name})</span>
                  )}
                </div>

                <div className="post-text">
                  {this.props.user.editable &&
                    (!this.state.element.admin || this.props.user.admin) && (
                      <div className="btn-group pull-right">
                        {this.props.user.admin &&
                          this.props.user.id !== this.state.element.id && (
                            <button
                              type="button"
                              onClick={() => this.delete(this.state.element)}
                              className="btn btn-danger"
                            >
                              Supprimer
                            </button>
                          )}
                        <Link
                          to={`/user/update/${this.state.element.id}`}
                          className="btn btn-warning"
                        >
                          Modifier
                        </Link>
                      </div>
                    )}
                  <div className="page-title">
                    <h2>
                      <span className="fa fa-arrow-circle-o-left" /> Projets
                    </h2>
                  </div>
                  <MyProjects user={this.state.element} />
                  <div className="page-title">
                    <h2>
                      <span className="fa fa-arrow-circle-o-left" /> Tâches
                    </h2>
                  </div>
                  <MyTasks user={this.state.element} />
                  <div className="page-title">
                    <h2>
                      <span className="fa fa-arrow-circle-o-left" /> Absences
                    </h2>
                  </div>
                  <MyHolidays user={this.state.element} />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default User;
