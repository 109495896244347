import React from "react";
import { Link } from "react-router-dom";

export default class Me extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { user } = this.props;

    const picture =
      user && user.image
        ? `${process.env.REACT_APP_IMAGES_URL}/${user.image.contentUrl}`
        : "/assets/images/users/no-image.jpg";

    const { pathname } = window.location;
    const regex = /admin/g;
    const isPageAdmin = pathname.match(regex);

    return user ? (
      <>
        <Link
          to="/user/profile"
          onClick={this.props.closeSidenav}
          className="profile-mini"
        >
          <img src={picture} alt={`${user.firstname} ${user.lastname}`} />
        </Link>
        <div className="profile">
          <div className="profile-image">
            <Link to="/user/profile" onClick={this.props.closeSidenav}>
              <img src={picture} alt={`${user.firstname} ${user.lastname}`} />
            </Link>
          </div>
          <div className="profile-data">
            <div className="profile-data-name">
              <Link to="/user/profile" onClick={this.props.closeSidenav}>
                {user.firstname} {user.lastname}{" "}
                {user.team && <span>({user.team.name})</span>}
              </Link>
            </div>
            {user.company && (
              <div className="profile-data-title">
                <Link to="/user/profile" onClick={this.props.closeSidenav}>
                  {user.company.name}
                </Link>
              </div>
            )}
          </div>
          <div className="profile-controls">
            {user.editable && !isPageAdmin && (
              <Link
                onClick={this.props.closeSidenav}
                to="/admin"
                alt="Configuration"
                className="profile-control-left"
              >
                <span className="fa fa-lock" />
              </Link>
            )}
            {user.editable && isPageAdmin && (
              <Link
                onClick={this.props.closeSidenav}
                to="/"
                alt="Home"
                className="profile-control-left"
              >
                <span className="fa fa-home" />
              </Link>
            )}
            <Link
              to="/user/profile"
              onClick={this.props.closeSidenav}
              className="profile-control-right"
            >
              <span className="fa fa-info" />
            </Link>
          </div>
        </div>
      </>
    ) : (
      ""
    );
  }
}
