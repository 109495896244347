import { ModelManager } from "@mjcom/model-manager";
import moment from "moment/moment";

function getBaseUrl() {
  const url = window.location.href.split("/");

  return `${url[0]}//${url[2]}`;
}

function getSlug() {
  const url = window.location.pathname.split("/");

  return url[2] ? url[2] : "";
}

function getUrl() {
  return `${getBaseUrl()}/app/${getSlug()}`;
}

export const OPERATION_KEY = `${window.location.host}/app/${getSlug()}`;

function isFinished(operation) {
  return !(
    operation &&
    operation.status !== "finished" &&
    operation.finishedAt &&
    moment().diff(moment(operation.finishedAt)) < 0
  );
}

function getOperation() {
  if (localStorage.getItem(OPERATION_KEY)) {
    const operation = JSON.parse(localStorage.getItem(OPERATION_KEY));
    const now = Date.now();

    if (
      Object.prototype.hasOwnProperty.call(operation, "cachedAt") &&
      operation.cachedAt >= now
    ) {
      return Promise.resolve(operation);
    }
  }

  return new Promise((resolve, reject) => {
    ModelManager.fetchList("/api/companies", { domain: getUrl() }, true)
      .then((res) => {
        if (res.getCount() > 0) {
          const operation = res.getMembers()[0];
          operation.cachedAt = Date.now() + 60 * 1000;
          localStorage.setItem(OPERATION_KEY, JSON.stringify(operation));
          resolve(operation);

          return operation;
        }

        reject(new Error("Operation not found"));

        return false;
      })
      .catch(reject);
  });
}

export const operationService = {
  getOperation,
  getUrl,
  isFinished,
  getSlug,
};
