import React from "react";
import { Input, SelectAsync } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "abortcontroller-polyfill";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { history } from "../../History";

class TaskTypeForm extends AsyncElementForm {
  constructor(props) {
    const element = {
      "@id": "/api/task_types",
      name: "",
      team: null,
    };

    const requiredFields = ["name", "team"];

    super(props, element, requiredFields);

    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    const violations = [];

    for (const requiredField of this.state.requiredFields) {
      if (
        this.state.element[requiredField] === "" ||
        this.state.element[requiredField] === null
      ) {
        violations.push({
          propertyPath: requiredField,
          message: "Ce champ est obligatoire.",
        });
      }
    }

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      const element = { ...this.state.element };
      element.team = element.team["value"];

      ModelManager.save(element)
        .finally(() => this.setState({ isLoading: false }))
        .then(() => {
          history.push("/admin/task-types");
          dispatch(
            alertActions.success("Votre type de tâche a été créé avec succés")
          );
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier votre saisie."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier votre saisie."
        )
      );
    }
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams");
  }

  render() {
    return (
      <div>
        {this.state.isLoading === false ? (
          <form className="task_type-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Input
                    name="name"
                    label="Nom"
                    type="text"
                    value={this.state.element.name}
                    errors={this.state.errors.name}
                    updateElement={this.updateElement}
                    required={this.isRequired("name")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    defaultOptions={true}
                    name="team"
                    label="Equipe"
                    value={this.state.element.team}
                    errors={this.state.errors.team}
                    loadOptions={this.loadTeams}
                    onChange={this.handleTeamChange}
                    placeholder="Equipe"
                    creatable={false}
                    required={this.isRequired("team")}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <Link
                className="btn btn-danger btn-rounded pull-left"
                to="/admin/task-types"
              >
                Annuler
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Soumettre
              </button>
            </div>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedTaskTypeForm = connect(mapStateToProps)(TaskTypeForm);
export { connectedTaskTypeForm as TaskTypeForm };
