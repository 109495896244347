import { combineReducers } from "redux";

import { alert, authentication, user } from "@mjcom/user-manager";

const rootReducer = combineReducers({
  authentication,
  user,
  alert,
});

export default rootReducer;
