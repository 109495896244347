import React from "react";
import { Date, SelectAsync, Textarea } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import moment from "moment";
import "moment/locale/fr";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "abortcontroller-polyfill";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { history } from "../../History";

class HolidayForm extends AsyncElementForm {
  constructor(props) {
    let element = {
      "@id": "/api/holidays",
      beginAt: moment(),
      endAt: moment(),
      user: null,
    };

    if (props.holiday) {
      element = { ...props.holiday };
      element.user = {
        value: element.user["@id"],
        label: `${element.user.firstname}  ${element.user.lastname}`,
      };
      element.beginAt = moment(element.beginAt);
      element.endAt = moment(element.endAt);
    }

    const requiredFields = ["beginAt", "endAt", "user"];

    super(props, element, requiredFields);

    this.handleUserChange = this.handleUserChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      const element = { ...this.state.element };
      element.user = element.user.value;
      ModelManager.save(element)
        .finally(() => this.setState({ isLoading: false }))
        .then(() => {
          history.push("/holidays");
          dispatch(
            alertActions.success("Votre action a été réalisée avec succés")
          );
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier votre saisie."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier votre saisie."
        )
      );
    }
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      { "order[fullname]": "asc" },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  render() {
    return (
      <div>
        {this.state.isLoading === false ? (
          <form className="project-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Textarea
                    className="form-group"
                    name="description"
                    label="Description"
                    type="text"
                    value={this.state.element.description}
                    errors={this.state.errors.description}
                    updateElement={this.updateElement}
                    required={this.isRequired("description")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="beginAt"
                    label="Date de début"
                    type="text"
                    value={this.state.element.beginAt}
                    errors={this.state.errors.beginAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("beginAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="endAt"
                    label="Date de fin"
                    type="text"
                    value={this.state.element.endAt}
                    errors={this.state.errors.endAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("endAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="user"
                    label="Utilisateur"
                    defaultOptions={true}
                    value={this.state.element.user}
                    errors={this.state.errors.user}
                    loadOptions={this.loadUsers}
                    onChange={this.handleUserChange}
                    placeholder="Entrez les premières lettres de l'utilisateur"
                    creatable={false}
                    required={this.isRequired("user")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <Link
                className="btn btn-danger btn-rounded pull-left"
                to="/holidays"
              >
                Annuler
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                {this.state.element.id ? "Modifier " : "Créer"}
              </button>
            </div>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedHolidayForm = connect(mapStateToProps)(HolidayForm);
export { connectedHolidayForm as HolidayForm };
