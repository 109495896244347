import React from "react";
import MyProjects from "../User/MyProjects";
import MyTasks from "../User/MyTasks";
import MyHolidays from "../User/MyHolidays";
import Check from "../Configuration/Check";

export default function Home(props) {
  return (
    <>
      <div className="page-title">
        <h2>
          <span className="fa fa-arrow-circle-o-left" /> Home
        </h2>
      </div>

      <div className="page-content-wrap">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-body">
                Bienvenue sur votre plan de charge, vous pouvez consulter ici
                les informations vous concernant.
                <Check user={props.user} />
              </div>
            </div>

            <div className="page-title">
              <h2>
                <span className="fa fa-arrow-circle-o-left" /> Mes projets
              </h2>
            </div>
            {props.user.id && <MyProjects user={props.user} />}

            <div className="page-title">
              <h2>
                <span className="fa fa-arrow-circle-o-left" /> Mes tâches
              </h2>
            </div>
            {props.user.id && <MyTasks user={props.user} />}

            <div className="page-title">
              <h2>
                <span className="fa fa-arrow-circle-o-left" /> Mes absences
              </h2>
            </div>
            {props.user.id && <MyHolidays user={props.user} />}
          </div>
        </div>
      </div>
    </>
  );
}
