import { ElementForm } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import "abortcontroller-polyfill";

export class AsyncElementForm extends ElementForm {
  constructor(props, element, requiredFields) {
    super(props, element, requiredFields);

    this.handleChange = this.handleChange.bind(this);
    this.loadModels = this.loadModels.bind(this);
  }

  submit(e) {
    super.submit(e);
  }

  handleChange(name, object) {
    const element = { ...this.state.element };
    element[name] = object ? object : null;
    this.setState({ element });
  }

  loadModels(
    inputValue,
    inputName,
    model,
    filters = { "order[beginAt]": "asc" },
    getLabel = function (element) {
      return `${element.name}`;
    }
  ) {
    const controller = `${model}Controller`;
    const signal = `${model}Signal`;

    if (this[controller] !== undefined) {
      // Cancel the previous request
      this[controller].abort();
    }

    // Feature detect
    if ("AbortController" in window) {
      this[controller] = new AbortController();
      this[signal] = this[controller].signal;
    }
    filters["itemsPerPage"] = 500;

    const options = Object.assign(filters, { [inputName]: inputValue });

    return new Promise((resolve) => {
      ModelManager.fetchList(`/api/${model}`, options, false, {
        signal: this[signal],
      })
        .then((collection) => {
          const values = [];
          collection.getMembers().map((element) => {
            const label = getLabel(element);
            const value = `/api/${model}/${element.id}`;
            values.push({ value, label });

            return element;
          });
          resolve(values);
        })
        .catch((error) => {
          if (error.name === "AbortError") return; // expected, this is the abort, so just return
          throw error; // must be some other error, handle however you normally would
        });
    });
  }
}
