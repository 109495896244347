import React from "react";

export class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      errors: props.errors,
    });
  }

  onChangeHandler(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });

    this.props.updateElement(this.props.name, event.target.files[0]);
  }

  getClassName() {
    return this.hasError() ? "is-invalid form-control" : "form-control";
  }

  hasError() {
    return this.state.errors && this.state.errors.length > 0;
  }

  render() {
    return (
      <>
        {this.state.selectedFile ? <p>{this.state.selectedFile.name}</p> : ""}
        <label>
          {this.props.label}
          {this.props.required ? "*" : ""}
        </label>
        <input type="file" name="file" onChange={this.onChangeHandler} />
        <div className="invalid-feedback">
          <ul className="errors">
            {this.hasError()
              ? this.state.errors.map((error, index) => {
                  return <li key={index + 1}>{error}</li>;
                })
              : ""}
          </ul>
        </div>
      </>
    );
  }
}
