import React from "react";
import { ElementForm, Input } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import "moment/locale/fr";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { history } from "../../History";

class TeamForm extends ElementForm {
  constructor(props) {
    const element = {
      "@id": "/api/teams",
      name: "",
    };

    const requiredFields = ["name"];

    super(props, element, requiredFields);
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;

    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      ModelManager.save(this.state.element)
        .finally(() => this.setState({ isLoading: false }))
        .then(() => {
          history.push("/admin/teams");
          dispatch(alertActions.success("Votre équipe a été créé avec succés"));
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          alertActions.error(
            "Une erreur est survenue. Merci de vérifier votre saisie."
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier votre saisie."
        )
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading === false ? (
          <form className="project-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Input
                    name="name"
                    label="Nom"
                    type="text"
                    value={this.state.element.name}
                    errors={this.state.errors.name}
                    updateElement={this.updateElement}
                    required={this.isRequired("name")}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <Link
                className="btn btn-danger btn-rounded pull-left"
                to="/admin/teams"
              >
                Annuler
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Soumettre
              </button>
            </div>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedTeamForm = connect(mapStateToProps)(TeamForm);
export { connectedTeamForm as TeamForm };
