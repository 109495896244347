import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class ProjectTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      activePage: 1,
      isLoading: true,
      filters: {
        showFinished: false,
      },
    };

    this.getElements = this.getElements.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentWillMount() {
    this.getElements(1, this.state.filters.showFinished);
  }

  getElements(page = 1, showFinished) {
    this.setState({ isLoading: true });

    const filters = { "order[beginAt]": "asc", page };

    if (!showFinished) {
      filters.isEnabled = true;
    }

    const uri = "/api/project_types";
    ModelManager.fetchList(uri, filters)
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          activePage: page,
        });
      });
  }

  handleCheckboxChange(event) {
    const showFinished = event.target.checked;
    const { filters } = this.state;
    filters.showFinished = showFinished;
    this.setState({ filters });
    this.getElements(1, showFinished);
  }

  handlePageChange(pageNumber) {
    this.getElements(pageNumber, this.state.filters.showFinished);
  }

  toggle(elem) {
    const element = { ...elem };
    element["@id"] = `${element["@id"]}/enabled`;

    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir ${
        element.isEnabled ? "désactiver" : "activer"
      } le type de projet "${element.name}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            element.isEnabled = !element.isEnabled;
            ModelManager.save(element)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                this.getElements(
                  this.state.activePage,
                  this.state.filters.showFinished
                );
              })
              .catch(() => {});
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    Moment.locale("fr");
    const elements =
      this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr key={element.id} className={!element.isEnabled && "inactive"}>
            <td>{element.name}</td>
            <td>{element.isEnabled ? "oui" : "non"}</td>
            <td>
              <FormattedDate value={element.createdAt} />
            </td>
            <td>{element.team.name}</td>
            {this.props.user.editable && (
              <td>
                <button
                  type="button"
                  onClick={() => this.toggle(element)}
                  className="btn btn-xs btn-danger"
                >
                  {element.isEnabled ? "Désactiver" : "Activer"}
                </button>
              </td>
            )}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="99" align="center">
            Aucun élement
          </td>
        </tr>
      );

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/admin">Admin</Link>
          </li>
          <li className="active">Types de Projets</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Types de projets
          </h2>
          {this.props.user.editable && (
            <div className="btn-group pull-right">
              <Link to="/admin/project-type/create" className="btn btn-success">
                Créer un type de projets
              </Link>
            </div>
          )}
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div id="filter" className="panel-body">
              <h3>Filtres</h3>
              <div className="col-md-2">
                <div className="checkbox pull-left">
                  <label htmlFor="checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={this.state.filters.showFinished}
                      onChange={this.handleCheckboxChange}
                    />
                    &nbsp;Afficher les éléments désactivés
                  </label>
                </div>
              </div>
            </div>
          </div>

          {!this.state.isLoading ? (
            <div className="panel-body">
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Actif ?</th>
                    <th>Date de création</th>
                    <th>Team</th>
                    {this.props.user.editable && <th>Actions</th>}
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={process.env.REACT_APP_PER_PAGE}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={5}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}
