import React from "react";
import { Link } from "react-router-dom";
import { ModelManager } from "@mjcom/model-manager";
import { UserForm } from "./UserForm";

export default class UpdateTask extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    this.getElement();
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/users", this.props.match.params.userId)
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {});
  }

  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/members">Utilisateurs</Link>
          </li>
          <li className="active">Modifier un utilisateur</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" />
            &nbsp;Modifier un utilisateur
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <p>
                Indiquer les informations suivantes pour modifier votre
                utilisateur
              </p>
            </div>
            <div className="panel-body form-group-separated">
              {this.state.isLoading && (
                <div className="loading">
                  <img src="/loading.gif" alt="loading" />
                </div>
              )}
              {this.state.isLoading === false && this.state.element && (
                <UserForm
                  connectedUser={this.props.user}
                  user={this.state.element}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
