import React from "react";

export default function AdminHome() {
  return (
    <>
      <div className="page-title">
        <h2>
          <span className="fa fa-arrow-circle-o-left" /> Admin
        </h2>
      </div>

      <div className="page-content-wrap">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-body">
                Bienvenue dans l&apos;interface d&apos;administration&nbsp; de
                votre plan de charge, vous pouvez modifier les paramètres de
                l&apos;outil.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
