import React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "@mjcom/form-component";

class Checkbox extends Input {
  handleChange(event) {
    let value = event.target.checked ? true : false;
    this.setState({ value });

    this.props.updateElement(this.props.name, value);
  }

  getClassName() {
    return this.hasError() ? "is-invalid form-check-input" : "form-check-input";
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-check">
          <input
            type="checkbox"
            id={this.props.name}
            name={this.props.name}
            value={this.state.value}
            onChange={this.handleChange}
            required={this.props.required}
            className={this.getClassName()}
            defaultChecked={this.props.defaultChecked}
          />
          <label htmlFor={this.props.name} className="form-check-label">
            {this.props.label}
            {this.props.required ? "*" : ""}
          </label>
          <div className="invalid-feedback">
            <ul className="errors">
              {this.hasError()
                ? this.state.errors.map((error, index) => {
                    return (
                      <li key={index + 1}>
                        <FormattedMessage id={error} />
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkbox;
