import { Link, Redirect, Route } from "react-router-dom";
import React from "react";
import { SessionManager } from "session-manager";
import { connect } from "react-redux";
import Me from "../User/Me";
import { operationService } from "../../Service/OperationService";

class ConnectedLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      reduced: false,
    };

    this.toggleReduce = this.toggleReduce.bind(this);
    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.closeSidenav = this.closeSidenav.bind(this);
  }

  toggleSidenav() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  toggleReduce() {
    this.setState((prevState) => ({ reduced: !prevState.reduced }));
  }

  closeSidenav() {
    this.setState({ isOpen: false });
  }

  render() {
    const { user } = this.props;
    return SessionManager.isAuthenticated() ? (
      <div
        className={`page-container ${
          this.state.reduced ? "x-navigation-reduced" : ""
        }`}
      >
        <div className="page-sidebar">
          <ul
            className={`x-navigation ${
              this.state.isOpen ? "x-navigation-open" : ""
            }`}
          >
            <li className="xn-logo">
              <a href={operationService.getUrl()} onClick={this.closeSidenav}>
                Workload
              </a>
              <button
                type="button"
                className="x-navigation-control"
                onClick={this.toggleSidenav}
              />
            </li>
            <li className="xn-profile">
              <Me closeSidenav={this.closeSidenav} user={user} />
            </li>

            {user.editable && (
              <li>
                <Link to="/admin" onClick={this.closeSidenav}>
                  Configuration
                </Link>
              </li>
            )}

            <li className="xn-title">Planning</li>
            <li>
              <Link to="/projects" onClick={this.closeSidenav}>
                Projets
              </Link>
            </li>
            <li>
              <Link to="/tasks" onClick={this.closeSidenav}>
                Tâches
              </Link>
            </li>
            <li>
              <Link to="/holidays" onClick={this.closeSidenav}>
                Absences
              </Link>
            </li>

            <li className="xn-title">Equipe</li>
            <li>
              <Link to="/members" onClick={this.closeSidenav}>
                Membres
              </Link>
            </li>
            <li>
              <Link to="/team/planning" onClick={this.closeSidenav}>
                Planning
              </Link>
            </li>
            <li>
              <Link to="/occupancy/rate" onClick={this.closeSidenav}>
                Taux d&apos;occupation
              </Link>
            </li>
          </ul>
        </div>
        <div className="page-content">
          <ul className="x-navigation x-navigation-horizontal x-navigation-panel">
            <li className="xn-icon-button">
              <button
                onClick={this.toggleReduce}
                className="x-navigation-minimize"
              >
                <span className="fa fa-dedent"></span>
              </button>
            </li>
            <li className="xn-icon-button pull-right last">
              <Link to="/logout" onClick={this.closeSidenav}>
                <span className="fa fa-power-off" />
              </Link>
            </li>
            {process.env.REACT_APP_VERSION && (
              <li className="pull-right navbar-text version">
                v{process.env.REACT_APP_VERSION}
              </li>
            )}
          </ul>
          <Route {...this.props} />
        </div>
      </div>
    ) : (
      <Redirect
        to={{ pathname: "/login", state: { from: this.props.location } }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

const connectedConnectedLayout = connect(mapStateToProps)(ConnectedLayout);
export { connectedConnectedLayout as ConnectedLayout };
