import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import { alertActions } from "@mjcom/user-manager";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { ROLES } from "../User/UserForm";
import { Filter } from "../Form/Filter";

class Members extends AsyncElementForm {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      count: 0,
      activePage: 1,
      isLoading: true,
      filters: {
        showFinished: false,
        user: null,
        team: null,
      },
    };

    this.getElements = this.getElements.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleChange(name, value) {
    const filters = { ...this.state.filters };
    filters[name] = value ? value.value : null;
    this.setState({ filters });
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      newValue ? newValue.value : null,
      this.state.filters.team
    );
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      this.state.filters.user,
      newValue ? newValue.value : null
    );
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      { "order[fullname]": "asc" },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams", {
      "order[name]": "asc",
    });
  }

  componentWillMount() {
    this.getElements(
      1,
      this.state.filters.showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  handleCheckboxChange() {
    const filters = { ...this.state.filters };
    filters.showFinished = !filters.showFinished;

    this.setState(
      {
        filters,
      },
      () => {
        this.getElements(
          1,
          this.state.filters.showFinished,
          this.state.filters.user,
          this.state.filters.team
        );
      }
    );
  }

  toggle(element) {
    const { dispatch } = this.props;
    const user = {};
    user["@type"] = element["@type"];
    user.id = element.id;
    user["@id"] = `${element["@id"]}/enabled`;
    user.enabled = element.enabled;

    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir ${
        element.enabled ? "désactiver" : "activer"
      } l'utilisateur "${element.firstname} ${element.lastname}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            user.enabled = !user.enabled;
            if (!user.enabled) {
              user["@id"] = `${element["@id"]}/disabled`;
            }

            ModelManager.save(user)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                this.getElements(
                  this.state.filters.pageNumber,
                  this.state.filters.showFinished,
                  this.state.filters.user,
                  this.state.filters.team
                );
              })
              .catch((e) => {
                console.log(e);
                dispatch(
                  alertActions.error(
                    e.violations && e.violations[0]
                      ? e.violations[0].message
                      : "Une erreur est survenue. Merci de vérifier votre saisie."
                  )
                );
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  getElements(page = 1, showFinished = false, user = null, team = null) {
    this.setState({ isLoading: true });

    const uri = "/api/users";

    const filters = { "order[fullname]": "asc", page };
    if (!showFinished) {
      filters.enabled = true;
    }

    if (user) {
      filters.id = user;
    }

    if (team) {
      filters.team = team;
    }

    if (this.props.projectId) {
      filters.project = this.props.projectId;
    }

    ModelManager.fetchList(uri, filters)
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          count: collection.getCount(),
          activePage: page,
        });
      });
  }

  handlePageChange(pageNumber) {
    this.getElements(
      pageNumber,
      this.state.filters.showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  render() {
    Moment.locale("fr");

    const elements =
      this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr key={element.id} className={!element.enabled ? "inactive" : ""}>
            <td>
              <Link to={`/user/${element.id}`}>
                <img
                  width="100"
                  alt={`${element.firstname} ${element.lastname}`}
                  src={
                    element && element.image
                      ? `${process.env.REACT_APP_IMAGES_URL}/${element.image.contentUrl}`
                      : "/assets/images/users/no-image.jpg"
                  }
                />
              </Link>
            </td>
            <td>
              <Link
                to={`/user/${element.id}`}
              >{`${element.firstname} ${element.lastname}`}</Link>
            </td>
            <td>{element.team ? element.team.name : ""}</td>
            <td>{element.email}</td>
            <td className="roles">
              {element.roles.map((role, i) => (
                <span key={i}>
                  {ROLES.find((e) => e.value === role)
                    ? ROLES.find((e) => e.value === role).label
                    : ""}
                </span>
              ))}
            </td>
            <td>{element.enabled ? "Oui" : "Non"}</td>
            {this.props.user.editable && (
              <td>
                {(!element.admin || this.props.user.admin) && (
                  <div className="btn-group">
                    <Link
                      to={`/user/update/${element.id}`}
                      className="btn btn-xs btn-warning"
                    >
                      Modifier
                    </Link>
                    {this.props.user.admin &&
                      this.props.user.id !== element.id && (
                        <button
                          type="button"
                          onClick={() => this.toggle(element)}
                          className="btn btn-xs btn-danger"
                        >
                          {element.enabled ? "Désactiver" : "Activer"}
                        </button>
                      )}
                  </div>
                )}
              </td>
            )}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="99" align="center">
            Aucun élement
          </td>
        </tr>
      );

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Membres</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Membres
          </h2>
          {this.props.user.editable && (
            <div className="btn-group pull-right">
              <Link to="/user/create" className="btn btn-success">
                Créer un utilisateur
              </Link>
            </div>
          )}
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div id="filter" className="panel-body">
              <h3>Filtres</h3>
              <div className="col-md-2">
                <div className="checkbox pull-left">
                  <label htmlFor="checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={this.state.filters.showFinished}
                      onChange={this.handleCheckboxChange}
                    />
                    &nbsp;Afficher les utilisateurs désactivés
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <Filter
                    name="team"
                    label="Team"
                    loadOptions={this.loadTeams}
                    onChange={this.handleTeamChange}
                    placeholder="Team"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <Filter
                    name="user"
                    label="Utilisateur"
                    loadOptions={this.loadUsers}
                    onChange={this.handleUserChange}
                    placeholder="Utilisateur"
                  />
                </div>
              </div>
            </div>
          </div>

          {!this.state.isLoading ? (
            <div className="col-md-12 table-responsive">
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Team</th>
                    <th>Email</th>
                    <th>Rôles</th>
                    <th>Actif ?</th>
                    {this.props.user.editable && <th>Actions</th>}
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={process.env.REACT_APP_PER_PAGE}
                    totalItemsCount={this.state.totalItems}
                    onChange={this.handlePageChange}
                    pageRangeDisplayed={5}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedMembers = connect(mapStateToProps)(Members);
export { connectedMembers as Members };
