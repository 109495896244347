import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { Filter } from "../Form/Filter";

export default class Projects extends AsyncElementForm {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      count: 0,
      activePage: 1,
      isLoading: true,
      filters: {
        showFinished: false,
        user: null,
        team: null,
      },
    };

    this.getElements = this.getElements.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
  }

  componentWillMount() {
    this.getElements(1, this.state.filters.showFinished);
  }

  getElements(page = 1, showFinished = false, user = null, team = null) {
    this.setState({ isLoading: true });

    const filters = { "order[beginAt]": "asc", page };
    if (!showFinished) {
      filters.isFinished = false;
    }

    if (user) {
      filters.user = user;
    }

    if (team) {
      filters.team = team;
    }

    const uri = "/api/projects";
    ModelManager.fetchList(uri, filters)
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          count: collection.getCount(),
          activePage: page,
        });
      });
  }

  handlePageChange(pageNumber) {
    this.getElements(
      pageNumber,
      this.state.filters.showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  handleCheckboxChange(event) {
    const showFinished = event.target.checked;
    const filters = { ...this.state.filters };
    filters.showFinished = showFinished;
    this.setState({ filters });
    this.getElements(
      1,
      showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  handleChange(name, value) {
    const filters = { ...this.state.filters };
    filters[name] = value ? value.value : null;
    this.setState({ filters });
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      newValue ? newValue.value : null,
      this.state.filters.team
    );
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      this.state.filters.user,
      newValue ? newValue.value : null
    );
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      { "order[fullname]": "asc", enabled: true },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams", {
      "order[name]": "asc",
    });
  }

  render() {
    Moment.locale("fr");
    const elements =
      this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr key={element.id} className={element.isFinished && "inactive"}>
            <td>
              <Link to={`/project/${element.id}`}>{element.name}</Link>
            </td>
            <td>
              <FormattedDate value={element.beginAt} />
            </td>
            <td>
              <FormattedDate value={element.endAt} />
            </td>
            <td>{element.projectType ? element.projectType.name : ""}</td>
            <td>
              {element.user &&
                `${element.user.firstname} ${element.user.lastname}`}
            </td>
            <td>{element.team.name}</td>
            <td>{element.isFinished ? "Oui" : "Non"}</td>
            {this.props.user.editable && (
              <td>
                <div className="btn-group">
                  <Link
                    to={`/project/update/${element.id}`}
                    className="btn btn-xs btn-warning"
                  >
                    Modifier
                  </Link>
                </div>
              </td>
            )}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="99" align="center">
            Aucun élement
          </td>
        </tr>
      );

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Projets</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Projets
          </h2>
          {this.props.user.editable && (
            <div className="btn-group pull-right">
              <Link to="/project/create" className="btn btn-success">
                Créer un projet
              </Link>
            </div>
          )}
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div id="filter" className="panel-body">
              <h3>Filtres</h3>
              <div className="col-md-2">
                <div className="checkbox pull-left">
                  <label htmlFor="checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={this.state.filters.showFinished}
                      onChange={this.handleCheckboxChange}
                    />{" "}
                    Afficher les projets terminés
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <Filter
                    name="team"
                    label="Team"
                    loadOptions={this.loadTeams}
                    onChange={this.handleTeamChange}
                    placeholder="Team"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <Filter
                    name="user"
                    label="Utilisateur"
                    loadOptions={this.loadUsers}
                    onChange={this.handleUserChange}
                    placeholder="Utilisateur"
                  />
                </div>
              </div>
            </div>
          </div>

          {!this.state.isLoading ? (
            <div className="col-md-12 table-responsive">
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Type de projet</th>
                    <th>Chef de projet</th>
                    <th>Team</th>
                    <th>Terminé</th>
                    {this.props.user.editable && <th>Actions</th>}
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={process.env.REACT_APP_PER_PAGE}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}
