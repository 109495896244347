import React from "react";
import { Link } from "react-router-dom";
import { ProjectForm } from "./ProjectForm";

export default class CreateProject extends React.Component {
  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Créer un projet</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Créer un projet
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <p>Indiquer les informations suivantes pour créer votre projet</p>
            </div>
            <div className="panel-body form-group-separated">
              <ProjectForm />
            </div>
          </div>
        </div>
      </>
    );
  }
}
