import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";

export default class MyTasks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      count: 0,
      activePage: 1,
      isLoading: true,
    };

    this.getElements = this.getElements.bind(this);
  }

  componentWillMount() {
    this.getElements();
  }

  getElements(page = 1) {
    this.setState({ isLoading: true });
    const uri = "/api/tasks";
    ModelManager.fetchList(uri, {
      "order[beginAt]": "asc",
      user: this.props.user.id,
      page,
      isFinished: false,
    })
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          count: collection.getCount(),
          activePage: page,
        });
      });
  }

  handlePageChange(pageNumber) {
    this.getElements(pageNumber);
  }

  render() {
    Moment.locale("fr");
    const elements =
      this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr key={element.id}>
            <td>
              <Link to={`/project/${element.project.id}`}>
                {element.project.name}
              </Link>
            </td>
            <td>
              <Link to={`/task/${element.id}`}>{element.name}</Link>
            </td>
            <td>{parseInt(element.time)} jour(s)</td>
            <td>{element.ratio}</td>
            <td>
              <FormattedDate value={element.beginAt} />
            </td>
            <td>
              <FormattedDate value={element.endAt} />
            </td>
            <td>{element.remaining} jour(s)</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="99" align="center">
            Aucun élement
          </td>
        </tr>
      );

    return (
      <>
        <div className="page-content-wrap">
          {!this.state.isLoading ? (
            <div className="panel-body table-responsive">
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Projet</th>
                    <th>Nom</th>
                    <th>Charge</th>
                    <th>Ratio</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Restant</th>
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={process.env.REACT_APP_PER_PAGE}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}
