import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import { Link } from "react-router-dom";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { confirmAlert } from "react-confirm-alert";
import { history } from "../../History";
import ProjectTasks from "../Task/ProjectTasks";
import { TaskForm } from "../Task/TaskForm";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
      isError: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    this.getElement();
  }

  onCreate() {
    window.location.reload();
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/projects", this.props.match.params.projectId)
      .finally(() => {
        this.setState({ isLoading: false, isError: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {
        this.setState({ isError: true });
      });
  }

  finish(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir ${
        element.isFinished ? "reprendre" : "terminer"
      } le projet "${element.name}" ?`,
      childrenElement: () => (
        <div className="info">
          Un projet terminé n'apparait plus dans la liste des projets en cours
          et ne peut plus recevoir de tâches.
        </div>
      ),
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true, isError: false });
            const element = { ...this.state.element };
            element.isFinished = !element.isFinished;
            element["@id"] = `${element["@id"]}/finish`;
            ModelManager.save(element)
              .finally(() =>
                this.setState({ isLoading: false, isError: false })
              )
              .then((project) => {
                this.setState({ element: project });
              })
              .catch(() => {
                this.setState({ isError: true });
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  delete(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir supprimer le projet "${element.name}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true, isError: false });
            ModelManager.delete(
              "/api/projects",
              this.props.match.params.projectId
            )
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                history.push("/projects");
              })
              .catch(() => {
                this.setState({ isError: true });
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    Moment.locale("fr");

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/projects">Projets en cours</Link>
          </li>
          <li className="active">Projet</li>
        </ul>

        {this.state.isError && (
          <div className="alert alert-danger">Une erreur est survenue</div>
        )}
        {this.state.isLoading && (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
        {!this.state.isLoading && !this.state.element && (
          <div>Projet introuvable</div>
        )}

        {!this.state.isLoading && this.state.element && (
          <div className="col-md-12">
            <div className="page-content-wrap posts">
              <div className="post-item">
                <div className="post-title">
                  {this.state.element.isFinished && (
                    <span className="badge badge-success">Terminée</span>
                  )}
                  {!this.state.element.isFinished &&
                    Moment(this.state.element.beginAt).isBefore(Moment()) && (
                      <span className="badge badge-warning">En cours</span>
                    )}{" "}
                  {this.state.element.name}{" "}
                  {this.state.element.projectType && (
                    <span>({this.state.element.projectType.name})</span>
                  )}
                </div>
                <div className="post-date">
                  <span className="fa fa-calendar" /> Du{" "}
                  <FormattedDate value={this.state.element.beginAt} /> au{" "}
                  <FormattedDate value={this.state.element.endAt} />
                  {this.state.element.user && (
                    <span>
                      {" "}
                      / {this.state.element.user.firstname}{" "}
                      {this.state.element.user.lastname}
                    </span>
                  )}
                </div>
                <div className="post-text">
                  {this.props.user.editable && (
                    <div className="btn-group pull-right">
                      <button
                        type="button"
                        onClick={() => this.delete(this.state.element)}
                        className="btn btn-danger"
                      >
                        Supprimer
                      </button>
                      <Link
                        to={`/project/update/${this.state.element.id}`}
                        className="btn btn-warning"
                      >
                        Modifier
                      </Link>
                    </div>
                  )}

                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={() => this.finish(this.state.element)}
                      className={`btn ${
                        !this.state.element.isFinished
                          ? "btn-success"
                          : "btn-warning"
                      }`}
                    >
                      {!this.state.element.isFinished
                        ? "Terminer"
                        : "Reprendre"}
                    </button>
                  </div>

                  <ProjectTasks projectId={this.state.element.id} />

                  {!this.state.element.isFinished && this.props.user.editable && (
                    <div className="page-content-wrap">
                      <div className="panel panel-default">
                        <div className="page-title">
                          <h2>
                            <span className="fa fa-arrow-circle-o-left" />{" "}
                            Ajouter une tâche
                          </h2>
                        </div>

                        <div className="panel-body">
                          <p>
                            Indiquer les informations suivantes pour créer votre
                            tâche.
                          </p>
                        </div>
                        <div className="panel-body form-group-separated">
                          <TaskForm
                            projectId={this.state.element.id}
                            onCreate={this.onCreate}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Project;
