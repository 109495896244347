import React, { useState, useEffect } from "react";
import { API_URL, getHeaders } from "@mjcom/model-manager";

function Version(props) {
  const [version, setVersion] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = {
      headers: getHeaders(),
      method: "GET",
    };

    setLoading(true);
    fetch(`${API_URL}/api/configuration/version`, init)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }

        return response.json();
      })
      .then((version) => {
        setVersion(version);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!loading &&
        (!process.env.REACT_APP_VERSION ||
          (version &&
            process.env.REACT_APP_VERSION &&
            process.env.REACT_APP_VERSION < version)) && (
          <div className="alert alert-warning update-version" role="alert">
            <p>
              <button onClick={() => document.location.reload(true)}>
                Une nouvelle version est disponible, cliquez ici
              </button>{" "}
              pour mettre à jour l'application.
            </p>
          </div>
        )}
    </>
  );
}

export default Version;
