import React from "react";
import { LoginForm } from "@mjcom/user-login-component";
import { operationService } from "../../Service/OperationService";

export default function Login() {
  return (
    <div className="login-container">
      <div className="login-box animated fadeInDown">
        <div className="login-logo" />
        <div className="login-body">
          <div className="login-title">
            <strong>Welcome</strong>, Please login
          </div>
          <LoginForm slug={operationService.getSlug()} />
        </div>
        <div className="login-footer">
          <div className="pull-left">&copy; 2019 Workload</div>
          <div className="pull-right" />
        </div>
      </div>
    </div>
  );
}
