import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import { Link } from "react-router-dom";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { confirmAlert } from "react-confirm-alert";
import { history } from "../../History";

class Task extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
      isError: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    this.getElement();
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/tasks", this.props.match.params.taskId)
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {
        this.setState({ isError: true });
      });
  }

  delete(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir supprimer la tâche "${element.name}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            ModelManager.delete("/api/tasks", this.props.match.params.taskId)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                history.push("/tasks");
              })
              .catch(() => {
                this.setState({ isError: true });
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  finish(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir ${
        element.isFinished ? "reprendre" : "terminer"
      } la tâche "${element.name}" ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            const element = { ...this.state.element };
            element.isFinished = !element.isFinished;
            element["@id"] = `${element["@id"]}/finish`;
            ModelManager.save(element)
              .finally(() => this.setState({ isLoading: false }))
              .then((task) => {
                this.setState({ element: task });
              })
              .catch(() => {
                this.setState({ isError: true });
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    Moment.locale("fr");

    return (
      <>
        {this.state.isError && (
          <div className="alert alert-danger">Une erreur est survenue</div>
        )}
        {this.state.isLoading && (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
        {!this.state.isLoading && !this.state.element && (
          <div>Tâche introuvable</div>
        )}

        {!this.state.isLoading && this.state.element && (
          <div className="col-md-12">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/projects">Projets</Link>
              </li>
              <li>
                <Link to={`/project/${this.state.element.project.id}`}>
                  {this.state.element.project.name}
                </Link>
              </li>
              <li className="active">Tâche</li>
            </ul>

            <div className="page-content-wrap posts">
              <div className="post-item">
                <div className="post-title">
                  {this.state.element.isFinished && (
                    <span className="badge badge-success">Terminée</span>
                  )}
                  {!this.state.element.isFinished &&
                    Moment(this.state.element.beginAt).isBefore(Moment()) && (
                      <span className="badge badge-warning">En cours</span>
                    )}{" "}
                  {this.state.element.name}{" "}
                  {this.state.element.taskType && (
                    <span>({this.state.element.taskType.name})</span>
                  )}{" "}
                  - {this.state.element.project.name}
                </div>
                <div className="post-date">
                  <span className="fa fa-calendar" /> Du{" "}
                  <FormattedDate value={this.state.element.beginAt} /> au{" "}
                  <FormattedDate value={this.state.element.endAt} />{" "}
                </div>

                {this.state.element.fullname && (
                  <div className="post-author">
                    <span className="fa fa-user" />
                    &nbsp;
                    {this.state.element.fullname}
                  </div>
                )}

                <div className="post-time">
                  <span className="fa fa-clock-o" />
                  &nbsp;
                  {this.state.element.time - this.state.element.remaining > 0
                    ? Number(
                        this.state.element.time - this.state.element.remaining
                      ).toFixed(2)
                    : 0}
                  &nbsp;jours&nbsp;/&nbsp;
                  {Number(this.state.element.time).toFixed(2)}
                  &nbsp;jours prévues (Utilisée à{" "}
                  {Number(this.state.element.ratio * 100).toFixed(0)}
                  %)
                </div>

                <div className="post-text">
                  {this.props.user.editable && (
                    <div className="btn-group pull-right">
                      <button
                        type="button"
                        onClick={() => this.delete(this.state.element)}
                        className="btn btn-danger"
                      >
                        Supprimer
                      </button>
                      <Link
                        to={`/task/update/${this.state.element.id}`}
                        className="btn btn-warning"
                      >
                        Modifier
                      </Link>
                    </div>
                  )}

                  <div className="btn-group">
                    <button
                      type="button"
                      onClick={() => this.finish(this.state.element)}
                      className={`btn  ${
                        !this.state.element.isFinished
                          ? "btn-success"
                          : "btn-warning"
                      }`}
                    >
                      {!this.state.element.isFinished
                        ? "Terminer"
                        : "Reprendre"}
                    </button>
                    <Link
                      to={`/project/${this.state.element.project.id}`}
                      className="btn btn-info"
                    >
                      Retour au projet
                    </Link>
                  </div>

                  <div className="panel panel-default col-md-12">
                    {this.state.element.description
                      ? this.state.element.description
                      : "Aucune description"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Task;
