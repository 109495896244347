import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { AsyncElementForm } from "../Form/AsyncElementForm";

export default class Holidays extends AsyncElementForm {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      activePage: 1,
      isLoading: true,
      filters: {
        showFinished: false,
      },
    };

    this.getElements = this.getElements.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentWillMount() {
    this.getElements(1, this.state.filters.showFinished);
  }

  getElements(page = 1, showFinished = false) {
    this.setState({ isLoading: true });
    const filters = { "order[beginAt]": "asc", page };
    const now = Moment().format("YYYY-MM-DD");

    if (!showFinished) {
      filters["endAt[after]"] = now;
    }
    const uri = "/api/holidays";
    ModelManager.fetchList(uri, filters)
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          activePage: page,
        });
      });
  }

  handleCheckboxChange(event) {
    const showFinished = event.target.checked;
    const { filters } = this.state;
    filters.showFinished = showFinished;
    this.setState({ filters });
    this.getElements(1, showFinished);
  }

  delete(element) {
    confirmAlert({
      title: "Confirmation",
      message: `Êtes vous sûr de vouloir supprimer cette absence ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.setState({ isLoading: true });
            ModelManager.delete("/api/holidays", element.id)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                this.getElements(1, this.state.filters.showFinished);
              })
              .catch(() => {
                this.setState({ isError: true });
              });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  }

  handlePageChange(pageNumber) {
    this.getElements(pageNumber, this.state.filters.showFinished);
  }

  render() {
    Moment.locale("fr");
    const elements =
      this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr
            key={element.id}
            className={element.remaining === 0 ? "inactive" : ""}
          >
            <td>
              <FormattedDate value={element.beginAt} />
            </td>
            <td>
              <FormattedDate value={element.endAt} />
            </td>
            <td>{element.fullname}</td>
            <td>{element.team}</td>
            <td>{element.remaining} jour(s)</td>
            {this.props.user.editable && (
              <td>
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={() => this.delete(element)}
                    className="btn btn-xs btn-danger"
                  >
                    Supprimer
                  </button>
                  <Link
                    to={`/holiday/update/${element.id}`}
                    className="btn btn-xs btn-warning"
                  >
                    Modifier
                  </Link>
                </div>
              </td>
            )}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={100} align="center">
            Aucun élement
          </td>
        </tr>
      );

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Absences</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Absences
          </h2>
          {this.props.user.editable && (
            <div className="btn-group pull-right">
              <Link to="/holiday/create" className="btn btn-success">
                Créer une absence
              </Link>
            </div>
          )}
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <h3>Filtres</h3>
              <div className="checkbox pull-left">
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={this.state.filters.showFinished}
                    onChange={this.handleCheckboxChange}
                  />{" "}
                  Afficher les absences terminées
                </label>
              </div>
            </div>
          </div>

          {!this.state.isLoading ? (
            <div>
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Assignée à</th>
                    <th>Team</th>
                    <th>Restant</th>
                    {this.props.user.editable && <th>Actions</th>}
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={parseInt(process.env.REACT_APP_PER_PAGE)}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}
