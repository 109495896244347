import React from "react";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="page-content">
          <h1>404 Page introuvable</h1>
          {this.props.message ? (
            <p className="not-found-information">{this.props.message}</p>
          ) : (
            <p>Page introuvable</p>
          )}
        </div>
      </div>
    );
  }
}
