import React from "react";
import { Link } from "react-router-dom";
import { ModelManager } from "@mjcom/model-manager";
import { HolidayForm } from "./HolidayForm";

export default class UpdateHoliday extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      element: null,
      isLoading: false,
      error: false,
    };

    this.getElement = this.getElement.bind(this);
  }

  getElement() {
    this.setState({ isLoading: true });
    ModelManager.fetch("/api/holidays", this.props.match.params.holidayId)
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        this.setState({ element });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  componentDidMount() {
    this.getElement();
  }

  render() {
    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/holidays">Absences</Link>
          </li>
          <li className="active">Modifier une absence</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Modifier une absence
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div className="panel-body">
              <p>
                Indiquer les informations suivantes pour modifier votre absence
              </p>
            </div>
            <div className="panel-body form-group-separated">
              {this.state.isLoading && (
                <div className="loading">
                  <img src="/loading.gif" alt="loading" />
                </div>
              )}
              {this.state.isLoading === false && this.state.element && (
                <HolidayForm holiday={this.state.element} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
