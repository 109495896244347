import React from "react";
import Pagination from "react-js-pagination";
import Moment from "moment/moment";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { Filter } from "../Form/Filter";
import { ModelManager } from "@mjcom/model-manager";
import { AsyncElementForm } from "../Form/AsyncElementForm";

export default class ProjectTasks extends AsyncElementForm {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      count: 0,
      activePage: 1,
      isLoading: true,
      filters: {
        showFinished: false,
        user: null,
        team: null,
      },
    };

    this.getElements = this.getElements.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadTeams = this.loadTeams.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleChange(name, value) {
    const filters = { ...this.state.filters };
    filters[name] = value ? value.value : null;
    this.setState({ filters });
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      newValue ? newValue.value : null,
      this.state.filters.team
    );
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
    this.getElements(
      1,
      this.state.filters.showFinished,
      this.state.filters.user,
      newValue ? newValue.value : null
    );
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      { "order[fullname]": "asc", enabled: true },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams", {
      "order[name]": "asc",
    });
  }

  componentWillMount() {
    this.getElements(
      1,
      this.state.filters.showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  handleCheckboxChange(event) {
    const showFinished = event.target.checked;
    const filters = { ...this.state.filters };
    filters.showFinished = showFinished;
    this.setState({ filters });
    this.getElements(
      1,
      showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  getElements(page = 1, showFinished = false, user = null, team = null) {
    this.setState({ isLoading: true });
    const filters = { "order[beginAt]": "asc", page };
    if (!showFinished) {
      filters.isFinished = false;
    }

    if (user) {
      filters.user = user;
    }

    if (team) {
      filters["project.team"] = team;
    }

    if (this.props.projectId) {
      filters.project = this.props.projectId;
    }

    const uri = "/api/tasks";
    ModelManager.fetchList(uri, filters)
      .finally(() => this.setState({ isLoading: false }))
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          count: collection.getCount(),
          activePage: page,
        });
      });
  }

  handlePageChange(pageNumber) {
    this.getElements(
      pageNumber,
      this.state.filters.showFinished,
      this.state.filters.user,
      this.state.filters.team
    );
  }

  render() {
    Moment.locale("fr");
    const elements =
      this.state.elements && this.state.elements.length > 0 ? (
        this.state.elements.map((element) => (
          <tr key={element.id}>
            <td>
              <Link to={`/task/${element.id}`}>{element.name}</Link>
            </td>
            <td>{element.fullname}</td>
            <td>{parseInt(element.time, 10)} jour(s)</td>
            <td>{element.ratio}</td>
            <td>
              <FormattedDate value={element.beginAt} />
            </td>
            <td>
              <FormattedDate value={element.endAt} />
            </td>
            <td>{element.remaining} jour(s)</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="99" align="center">
            Aucun élement
          </td>
        </tr>
      );

    const titles = [
      [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ],
    ];
    const tasks =
      this.state.elements &&
      this.state.elements.length > 0 &&
      this.state.elements.map((element) => [
        element.id,
        element.name,
        element.user ? element.user.fullname : "",
        new Date(element.beginAt),
        new Date(element.endAt),
        element.time,
        Number(
          ((element.time - element.remaining) * 100) / element.time
        ).toFixed(2),
        null,
      ]);

    const gantt = titles.concat(tasks);

    return (
      <>
        <div className="panel panel-default">
          <div id="filter" className="panel-body">
            <h3>Filtres</h3>
            <div className="col-md-2">
              <div className="checkbox pull-left">
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={this.state.filters.showFinished}
                    onChange={this.handleCheckboxChange}
                  />{" "}
                  Afficher les tâches terminées
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="">
                <Filter
                  name="team"
                  label="Team"
                  value={this.state.filters.team}
                  loadOptions={this.loadTeams}
                  onChange={this.handleTeamChange}
                  placeholder="Team"
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="">
                <Filter
                  name="user"
                  label="Utilisateur"
                  value={this.state.filters.user}
                  loadOptions={this.loadUsers}
                  onChange={this.handleUserChange}
                  placeholder="Utilisateur"
                />
              </div>
            </div>
          </div>

          {!this.state.isLoading ? (
            <div>
              <table className="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Assignée à</th>
                    <th>Charge</th>
                    <th>Ratio</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Restant</th>
                  </tr>
                </thead>

                <tbody>{elements}</tbody>
              </table>

              {this.state.totalItems > process.env.REACT_APP_PER_PAGE ? (
                <nav className="pagination">
                  <Pagination
                    disabledClass="disabled"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={process.env.REACT_APP_PER_PAGE}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </nav>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>

        <div className="panel panel-default">
          <div className="page-title">
            <h2>
              <span className="fa fa-arrow-circle-o-left" /> Gantt
            </h2>
          </div>
          {!this.state.isLoading ? (
            <div className="clearfix">
              {tasks.length > 0 ? (
                <Chart
                  chartLanguage="fr"
                  width="100%"
                  chartType="Gantt"
                  loader={
                    <div className="loading">
                      <img src="/loading.gif" alt="loading" />
                    </div>
                  }
                  data={gantt}
                  options={{
                    gantt: {
                      trackHeight: 30,
                    },
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              ) : (
                <div align="center">Aucun élément</div>
              )}
            </div>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </>
    );
  }
}
