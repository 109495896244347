import { SelectAsync } from "@mjcom/form-component";
import React from "react";

export function Filter(props) {
  return (
    <SelectAsync
      isClearable={true}
      defaultOptions={true}
      creatable={false}
      required={false}
      {...props}
    />
  );
}
