import React from "react";
import { Date, Input, SelectAsync, Textarea } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { alertActions } from "@mjcom/user-manager";
import moment from "moment";
import "moment/locale/fr";
import { connect } from "react-redux";
import "abortcontroller-polyfill";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { history } from "../../History";

export const RECURRENCES = [
  { label: "Lundi", value: "1" },
  { label: "Mardi", value: "2" },
  { label: "Mercredi", value: "3" },
  { label: "Jeudi", value: "4" },
  { label: "Vendredi", value: "5" },
  { label: "Samedi", value: "6" },
  { label: "Dimanche", value: "7" },
];

export const DEFAULT_RECURRENCES = ["1", "2", "3", "4", "5"];

class TaskForm extends AsyncElementForm {
  constructor(props) {
    let element = {
      "@id": "/api/tasks",
      name: "",
      description: "",
      endAt: moment(),
      recurrences: DEFAULT_RECURRENCES.map((element) => {
        return {
          value: element,
          label: RECURRENCES.find((recurrence) => recurrence.value === element)
            .label,
        };
      }),
      ratio: { value: 100, label: "100 %" },
      user: null,
      taskType: null,
      project: props.projectId
        ? { value: `/api/projects/${props.projectId}` }
        : null,
      beginAt: moment(),
    };

    if (props.task) {
      element = { ...props.task };
      element.ratio = {
        value: element.ratio * 100,
        label: `${element.ratio * 100} %`,
      };
      if (element.user) {
        element.user = {
          value: element.user["@id"],
          label: `${element.user.firstname}  ${element.user.lastname}`,
        };
      }
      element.recurrences = props.task.recurrences.map((element) => {
        return {
          value: element,
          label: RECURRENCES.find((recurrence) => recurrence.value === element)
            .label,
        };
      });
      element.taskType = {
        value: element.taskType["@id"],
        label: element.taskType["name"],
      };
      element.project = {
        value: element.project["@id"],
        label: element.project["name"],
      };
      element.beginAt = moment(element.beginAt);
      element.endAt = moment(element.endAt);
    }

    const requiredFields = [
      "name",
      "description",
      "endAt",
      "ratio",
      "beginAt",
      "taskType",
      "project",
      "beginAt",
      "recurrences",
    ];

    super(props, element, requiredFields);

    this.handleTaskTypeChange = this.handleTaskTypeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleRatioChange = this.handleRatioChange.bind(this);
    this.handleRecurrencesChange = this.handleRecurrencesChange.bind(this);
    this.loadModels = this.loadModels.bind(this);
    this.loadRecurrences = this.loadRecurrences.bind(this);
    this.loadTaskTypes = this.loadTaskTypes.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
  }

  handleUserChange(newValue) {
    this.handleChange("user", newValue);
  }

  handleProjectChange(newValue) {
    this.handleChange("project", newValue);
  }

  handleTaskTypeChange(newValue) {
    this.handleChange("taskType", newValue);
  }

  handleRecurrencesChange(newValue) {
    this.handleChange("recurrences", newValue);
  }

  handleRatioChange(newValue) {
    console.log(newValue);
    this.handleChange("ratio", newValue);
  }

  loadUsers(inputValue) {
    return this.loadModels(
      inputValue,
      "fullname",
      "users",
      {
        "order[firstname]": "asc",
        "order[lastname]": "asc",
        roles: "ROLE_USER",
        enabled: true,
      },
      (element) => `${element.firstname} ${element.lastname}`
    );
  }

  loadProjects(inputValue) {
    const filters = { "order[name]": "asc", isFinished: false };
    return this.loadModels(inputValue, "name", "projects", filters);
  }

  // eslint-disable-next-line class-methods-use-this
  loadRecurrences() {
    return Promise.resolve(RECURRENCES);
  }

  // eslint-disable-next-line class-methods-use-this
  loadRatios() {
    const ratios = [];
    let i = 0;
    while (i <= 100) {
      ratios.push({ value: i, label: `${i} %` });
      i += 1;
    }

    return Promise.resolve(ratios);
  }

  loadTaskTypes(inputValue) {
    return this.loadModels(inputValue, "name", "task_types", {
      "order[beginAt]": "asc",
      isEnabled: true,
    });
  }

  submit(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      const element = { ...this.state.element };
      element.ratio = (element.ratio.value / 100).toString(10);
      element.project = element.project.value;
      element.taskType = element.taskType.value;
      element.user = element.user ? element.user.value : null;
      element.recurrences = element.recurrences.map((element) => element.value);

      ModelManager.save(element)
        .finally(() => this.setState({ isLoading: false }))
        .then((task) => {
          if (typeof this.props.onCreate === "function") {
            this.props.onCreate();
          } else {
            history.push(`/task/${task.id}`);
          }
          dispatch(
            alertActions.success("Votre action a été réalisée avec succés")
          );
        })
        .catch((error) => {
          if (error.violations) {
            this.mapErrors(error.violations);
          } else {
            this.cleanErrors();
          }
          dispatch(
            alertActions.error(
              "Une erreur est survenue. Merci de vérifier votre saisie."
            )
          );
        });
    } else {
      this.mapErrors(violations);
      dispatch(
        alertActions.error(
          "Une erreur est survenue. Merci de vérifier votre saisie."
        )
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading === false ? (
          <form className="task-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Input
                    name="name"
                    label="Nom"
                    type="text"
                    value={this.state.element.name}
                    errors={this.state.errors.name}
                    updateElement={this.updateElement}
                    required={this.isRequired("name")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Textarea
                    name="description"
                    label="Description"
                    type="text"
                    value={this.state.element.description}
                    errors={this.state.errors.description}
                    updateElement={this.updateElement}
                    required={this.isRequired("description")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="beginAt"
                    label="Date de début"
                    type="text"
                    isOutsideRange={() => false}
                    value={this.state.element.beginAt}
                    errors={this.state.errors.beginAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("beginAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <Date
                    className="form-group"
                    name="endAt"
                    label="Date de fin"
                    type="text"
                    isOutsideRange={() => false}
                    value={this.state.element.endAt}
                    errors={this.state.errors.endAt}
                    updateElement={this.updateElement}
                    required={this.isRequired("endAt")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    placeholder="Ratio"
                    defaultOptions={true}
                    name="ratio"
                    label="Ratio (poucentage d'utilisation de la ressource)"
                    loadOptions={this.loadRatios}
                    onChange={this.handleRatioChange}
                    value={this.state.element.ratio}
                    errors={this.state.errors.ratio}
                    creatable={false}
                    required={this.isRequired("ratio")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="recurrences"
                    label="Récurrences"
                    isMulti={true}
                    defaultOptions={true}
                    value={this.state.element.recurrences}
                    errors={this.state.errors.recurrences}
                    loadOptions={this.loadRecurrences}
                    onChange={this.handleRecurrencesChange}
                    placeholder="Récurrences"
                    creatable={false}
                    required={this.isRequired("recurrences")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="taskType"
                    label="Type de tâche"
                    defaultOptions={true}
                    value={this.state.element.taskType}
                    errors={this.state.errors.taskType}
                    loadOptions={this.loadTaskTypes}
                    onChange={this.handleTaskTypeChange}
                    placeholder="Type de tâche"
                    creatable
                    required={this.isRequired("taskType")}
                  />
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="col-md-8">
                  <SelectAsync
                    name="user"
                    label="Utilisateur"
                    defaultOptions={true}
                    value={this.state.element.user}
                    errors={this.state.errors.user}
                    loadOptions={this.loadUsers}
                    onChange={this.handleUserChange}
                    placeholder="Entrez les premières lettres de l'utilisateur"
                    creatable={false}
                    required={this.isRequired("user")}
                  />
                </div>
              </div>

              {!this.props.projectId && (
                <div className="form-group col-md-12">
                  <div className="col-md-8">
                    <SelectAsync
                      defaultOptions={true}
                      name="project"
                      label="Projet"
                      value={this.state.element.project}
                      errors={this.state.errors.project}
                      loadOptions={this.loadProjects}
                      onChange={this.handleProjectChange}
                      placeholder="Entrez les premières lettres du projet"
                      creatable={false}
                      required={this.isRequired("project")}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-8">
              <button
                type="submit"
                className="btn btn-primary btn-rounded pull-right"
                onClick={this.submit}
              >
                Soumettre
              </button>
            </div>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </form>
        ) : (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedTaskForm = connect(mapStateToProps)(TaskForm);
export { connectedTaskForm as TaskForm };
