/* eslint-disable max-len */
import { Link, Redirect, Route } from "react-router-dom";
import React from "react";
import { SessionManager } from "session-manager";
import { connect } from "react-redux";
import Me from "../User/Me";
import { operationService } from "../../Service/OperationService";

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.closeSidenav = this.closeSidenav.bind(this);
  }

  toggleSidenav() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  closeSidenav() {
    this.setState({ isOpen: false });
  }

  render() {
    const { user } = this.props;
    return SessionManager.isAuthenticated() ? (
      <div className="page-container">
        <div className="page-sidebar">
          <ul
            className={`x-navigation ${
              this.state.isOpen && "x-navigation-open"
            }`}
          >
            <li className="xn-logo">
              <a href={operationService.getUrl()} onClick={this.closeSidenav}>
                Workload
              </a>
              <button
                type="button"
                className="x-navigation-control"
                onClick={this.toggleSidenav}
              />
            </li>
            <li className="xn-profile">
              <Me closeSidenav={this.closeSidenav} user={user} />
            </li>

            {user.editable && (
              <li>
                <Link to="/">Retourner au planning</Link>
              </li>
            )}

            <li className="xn-title">Admin</li>
            <li>
              <Link onClick={this.closeSidenav} to="/admin/project-types">
                Types de projets
              </Link>
            </li>
            <li>
              <Link onClick={this.closeSidenav} to="/admin/task-types">
                Types de tâches
              </Link>
            </li>
            <li>
              <Link onClick={this.closeSidenav} to="/admin/teams">
                Equipes
              </Link>
            </li>
          </ul>
        </div>
        <div className="page-content">
          <ul className="x-navigation x-navigation-horizontal x-navigation-panel">
            <li className="xn-icon-button pull-right last">
              <Link onClick={this.closeSidenav} to="/logout">
                <span className="fa fa-power-off" />
              </Link>
            </li>
          </ul>

          <Route {...this.props} />
        </div>
      </div>
    ) : (
      <Redirect
        to={{ pathname: "/login", state: { from: this.props.location } }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { alert, user } = state;

  return {
    alert,
    user,
  };
}

const connectedAdminLayout = connect(mapStateToProps)(AdminLayout);
export { connectedAdminLayout as AdminLayout };
