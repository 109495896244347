import React from "react";
import { Link } from "react-router-dom";
import { API_URL, getHeaders } from "@mjcom/model-manager";
import { connect } from "react-redux";
import { userActions } from "@mjcom/user-manager";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import { AsyncElementForm } from "../Form/AsyncElementForm";
import { Filter } from "../Form/Filter";

class OccupancyRate extends AsyncElementForm {
  constructor(props) {
    super(props);

    this.state = {
      occupancies: null,
      isLoading: false,
      description: null,
      filters: {
        team: null,
      },
    };

    this.loadTeams = this.loadTeams.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getOccupancyRate(team) {
    const { dispatch } = this.props;

    const init = {
      headers: getHeaders(),
      method: "GET",
    };

    this.setState({ isLoading: true });
    fetch(`${API_URL}/api/occupancy?team=${team}`, init)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            dispatch(userActions.reconnect());
          }

          return Promise.reject(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        this.setState({ occupancies: data });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleChange(name, value) {
    const filters = { ...this.state.filters };
    filters[name] = value ? value.value : null;
    this.setState({ filters });
  }

  handleTeamChange(newValue) {
    this.handleChange("team", newValue);
    if (newValue) {
      this.getOccupancyRate(newValue.value);
    }
  }

  loadTeams(inputValue) {
    return this.loadModels(inputValue, "name", "teams", {
      "order[name]": "asc",
    });
  }

  render() {
    const columns = [
      {
        Header: "Prénom",
        accessor: "firstname",
        Cell: (props) => <span className="ratio">{props.value}</span>,
      },
      {
        Header: "Nom",
        accessor: "lastname",
        Cell: (props) => <span className="ratio">{props.value}</span>,
      },
      {
        Header: "Taux d'occupation",
        accessor: "rate",
        Cell: (props) => <span className="ratio">{props.value}%</span>,
      },
    ];

    return (
      <>
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">Taux d&apos;occupation</li>
        </ul>

        <div className="page-title">
          <h2>
            <span className="fa fa-arrow-circle-o-left" /> Taux
            d&apos;occupation
          </h2>
        </div>

        <div className="page-content-wrap">
          <div className="panel panel-default">
            <div id="filter" className="panel-body">
              <h3>Filtres</h3>

              <div className="col-md-3">
                <div className="">
                  <Filter
                    name="team"
                    label="Team"
                    loadOptions={this.loadTeams}
                    onChange={this.handleTeamChange}
                    placeholder="Team"
                  />
                </div>
              </div>
            </div>
          </div>

          {this.state.isLoading && (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
          {!this.state.isLoading &&
            !this.state.occupancies &&
            this.state.team === null && (
              <div className="col-md-12">Impossible de charger le planning</div>
            )}
          {!this.state.isLoading && this.state.filters.team === null && (
            <div className="col-md-12">Merci de choisir une équipe.</div>
          )}

          {!this.state.isLoading &&
            this.state.occupancies &&
            this.state.filters.team && (
              <div className="panel-body table-responsive">
                <ReactTableFixedColumns
                  data={this.state.occupancies}
                  columns={columns}
                  defaultPageSize={this.state.occupancies.length}
                  style={{ "max-height": 500 }}
                  showPagination={false}
                  filterable={false}
                  className="-striped -highlight"
                />
              </div>
            )}
        </div>
      </>
    );
  }
}

function mapStateToProps() {
  return {};
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const connectedOccupancyRate = connect(mapStateToProps)(OccupancyRate);
export { connectedOccupancyRate as OccupancyRate };
